import moment from 'moment'
import { useEffect, useState } from 'react';
import firebase from 'firebase';
import './Footer.css'
import logo from './Imagens/logo.png';
import ajp from './Imagens/ajp.jpg';
import procompetidor from './Imagens/procompetidor.png';
import whatsapp from './Imagens/whatsapp.png';


export default function Footer() {

  const [configuracoes, setConfiguracoes] = useState({});
  const [servicos] = useState({
    configuracoes: firebase.firestore().collection("configuracoes")
  })

  useEffect(() => {
    servicos.configuracoes.get().then((snap => {
      const config = snap.docs.map(c => c.data())
      if (config[0]) {
        setConfiguracoes(config[0]);
      }
    }))
  }, [])


  return (
    <>

      <div class="whatsapp-icon">
        <a href={`https://api.whatsapp.com/send?phone=55${configuracoes?.whatsapp?.numero}&text=${configuracoes?.whatsapp?.mensagem}`} target="_blank" title='WhatsApp'>
          <img src={whatsapp} alt="WhatsApp Icon" />
        </a>
      </div>


      {/* <!-- Footer --> */}

      <footer id="main-footer" className="py-2">
        <div className="container footer-container">
          <div style={{ display: "inline-flex", flexDirection: "column", paddingTop: "10px" }} >
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <img src={logo} alt="" style={{ margin: "10px 0 10px 0" }} />
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <label><b>Em parceria com:</b> </label>
              <a href='https://procompetidor.com.br/'>
                <img src={procompetidor} alt="" style={{ margin: "10px 0 10px 0", height: '50px', width: '280px' }} />
              </a>
            </div>

            <p style={{ textAlign: 'justify' }}>Participe dos Eventos <span style={{ fontFamily: 'monospace', fontStyle: 'italic', fontWeight: 'bolder', fontSize: '16px' }}>AJP</span> Brasil e aproveite a praticidade de poder utilizar seu cadastro ProCompetidor  </p>
          </div>

          {/* <div>
      <p>Participe dos Eventos AJP no Brasil e aproveite a praticidade de poder utilizar seu cadastro ProCompetidor </p>
      </div> */}

          <div style={{ textAlign: 'center' }}>
            <h3>Redes Sociais</h3>
            <ul class="list">

              <div class="social">

                <li>

                  <a href="https://www.instagram.com/ajp_brasil/" target="_blank"><i class="fab fa-instagram fa-2x"></i></a>
                  <a href="https://www.facebook.com/AJPTour/" target="_blank" style={{ marginLeft: "10px" }}><i class="fab fa-facebook fa-2x"></i></a>


                </li>

                <li>
                  <a href="https://x.com/ajptour" target="_blank"><i class="fab fa-twitter fa-2x"></i></a>
                  <a href="https://www.youtube.com/channel/UC7m2_Wx33tfrMYYVMVqIOzg" target="_blank"><i class="fab fa-youtube fa-2x"></i></a>
                </li>
                <li>
                  <a href="https://lnk.bio/ajp_brasil?fbclid=PAZXh0bgNhZW0CMTEAAaba1IrB2V2rjXfv0jTI1dBJ23NWJWG1zu_0nAdq7185mAXl6B6qqiV5aaE_aem_NNfKcj1vMAhGz3pMW336xw" target="_blank"><i class="fa fa-info-circle fa-2x"></i></a>
                  <i style={{ visibility: 'hidden' }} class="fa fa-info-circle fa-2x"></i>
                </li>

              </div>
            </ul>
          </div>

          <div>
            <h3>Acesso rápido</h3>
            <ul class="list">
              <li><a href="/">Início</a></li>
              <li><a href="/eventos?p=andamento">Eventos</a></li>
              <li><a href="/contato">Contato</a></li>
              <li><a href="/sobre">Sobre</a></li>
            </ul>
          </div>

          <div>
            <p><span> Abu Dhabi Jiu Jitsu Pro™ (AJP) Brasil {moment().format("YYYY")} &copy; Todos os Direitos Reservados </span></p>
          </div>
        </div>
        <div className='termos' >
          <a>Termos de uso</a> |
          <a> Política de Privacidade</a>
        </div>
      </footer>

    </>
  );
}