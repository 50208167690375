import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import moment from 'moment'
import firebase from 'firebase'
import Swal from 'sweetalert2'
import Grid from '@material-ui/core/Grid';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        height: '100%',
        display: 'flex',
        backgroundColor: 'red'
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
        color: 'red',
    },
    avatar: {
        width: 120,
        height: 120
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        whiteSpace: 'nowrap',
        marginBottom: theme.spacing(1),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}));

export default function Evento() {
    const classes = useStyles();
    const [infor] = useState([])
    const [servicos] = useState({
        eventos: firebase.firestore().collection("eventos"),
        usuarios: firebase.firestore().collection("usuarios"),
        inscricoes: firebase.firestore().collection("inscricoes"),
        auth: firebase.auth()
    })
    const [id] = useState(window.location.pathname.replace('/evento/', ''))
    const [informacoes, setInformacoes] = useState('')
    const [user, setUser] = useState(false)
    const [evento, setEvento] = useState({})
    console.log('evento: ', evento);

    const [dadosInscricao, setIncricao] = useState({})
    const [administrador, setAdministrador] = useState({})
    const [validaChecagem, setValidaChecagem] = useState(0)
    const [validaResultados, setValidaResultados] = useState(0)
    console.log('validaResultados: ', validaResultados);

    const [validaChave, setValidaChave] = useState(0)
    const [validaInicioInscricao, setValidaInicioInscricao] = useState(0)
    const [validaFimInscricao, setValidaFimInscricao] = useState(0)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        servicos.auth.onAuthStateChanged((snap) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))
            if (snap) {
                setUser(true)
                servicos.usuarios.doc(snap.uid).get().then((dt) => {
                    if (dt.data().usuario === 2) {
                        servicos.inscricoes
                            .where('usuario.id', '==', snap.uid)
                            .where('evento.id', '==', id)
                            .get().then((e) => {
                                if (e.size > 0) {
                                    const insc = e.docs.map((d) => d.data())[0]
                                    setIncricao(insc)
                                }
                            })
                    } else {
                        servicos.inscricoes
                            .where('responsavel.id', '==', snap.uid)
                            .where('evento.id', '==', id)
                            .get().then((e) => {
                                if (e.size > 0) {
                                    const insc = e.docs.map((d) => d.data())[0]
                                    setIncricao(insc)
                                }
                            })
                    }
                })
            } else if (idUser) {
                setUser(true)
                servicos.usuarios.doc(idUser).get().then((dt) => {
                    if (dt.data().usuario === 2) {
                        servicos.inscricoes
                            .where('usuario.id', '==', idUser)
                            .where('evento.id', '==', id)
                            .get().then((e) => {
                                if (e.size > 0) {
                                    const insc = e.docs.map((d) => d.data())[0]
                                    setIncricao(insc)
                                }
                            })
                    } else {
                        servicos.inscricoes
                            .where('responsavel.id', '==', idUser)
                            .where('evento.id', '==', id)
                            .get().then((e) => {
                                if (e.size > 0) {
                                    const insc = e.docs.map((d) => d.data())[0]
                                    setIncricao(insc)
                                }
                            })
                    }
                })
            }
            servicos.eventos.doc(id).get().then((snap) => {
                setEvento(snap.data())
                servicos.usuarios.doc(snap.data().administrador.id).get().then((admin) => {
                    setAdministrador(admin.data())
                })
                var dateChecagem = moment(snap.data().checagem).format('DD/MM/YYYY')
                var dataChecagem = moment(dateChecagem, 'DD/MM/YYYY');
                var dateChave = moment(snap.data().chaves).format('DD/MM/YYYY')
                var dataChave = moment(dateChave, 'DD/MM/YYYY');
                var dateInicioInscricao = moment(snap.data().inicioInscricao).format('DD/MM/YYYY')
                var dataInicioInscricao = moment(dateInicioInscricao, 'DD/MM/YYYY');
                var dateFimInscricao = moment(snap.data().fimInscricao).format('DD/MM/YYYY')
                var dataFimInscricao = moment(dateFimInscricao, 'DD/MM/YYYY');
                var dateInicioEvento = moment(snap.data().inicioEvento).format('DD/MM/YYYY')
                var dataInicioEvento = moment(dateInicioEvento, 'DD/MM/YYYY');

                var date1 = moment().format('DD/MM/YYYY')
                var data1 = moment(date1, 'DD/MM/YYYY');

                setValidaChecagem(dataChecagem.diff(data1, 'days'))
                setValidaInicioInscricao(dataInicioInscricao.diff(data1, 'days'))
                setValidaFimInscricao(dataFimInscricao.diff(data1, 'days'))
                setValidaResultados(dataInicioEvento.diff(data1, 'days'))
                setValidaChave(dataChave.diff(data1, 'days'))
                setInformacoes(snap.data().sobre)
                setLoading(false)
            })
        })

    }, [])

    function inscricao() {
        servicos.auth.onAuthStateChanged((snap) => {
            let idUser = JSON.parse(localStorage.getItem('usuarioId'))
            console.log(idUser)
            if (snap) {
                servicos.usuarios.doc(snap.uid).get().then((dt) => {
                    if (dt.data().usuario === 2) {
                        servicos.inscricoes
                            .where('usuario.id', '==', snap.uid)
                            .where('evento.id', '==', id)
                            .get().then((e) => {
                                if (e.size > 0) {
                                    if (!evento.inscricoesMultiplas || evento?.inscricoesMultiplas === 0) {
                                        const insc = e.docs.map((d) => d.data())[0].id
                                        window.location = window.location.href.replace('/evento/' + id, '/minhaInscricao/' + insc)
                                    } else if (evento?.inscricoesMultiplas === 1) {
                                        window.location = window.location.href.replace('/evento/', '/listaInscricoes/')
                                    }

                                } else {
                                    window.location = window.location.href.replace('/evento/', '/inscricao/')
                                }
                            })
                    } else {
                        window.location = window.location.href.replace('/evento/', '/listaCompetidores/')
                    }
                })
            } else if (idUser) {
                servicos.usuarios.doc(idUser).get().then((dt) => {
                    if (dt.data().usuario === 2) {
                        servicos.inscricoes
                            .where('usuario.id', '==', idUser)
                            .where('evento.id', '==', id)
                            .get().then((e) => {
                                if (e.size > 0) {
                                    if (!evento.inscricoesMultiplas || evento?.inscricoesMultiplas === 0) {
                                        const insc = e.docs.map((d) => d.data())[0].id
                                        window.location = window.location.href.replace('/evento/' + id, '/minhaInscricao/' + insc)
                                    } else if (evento.inscricoesMultiplas && evento?.inscricoesMultiplas === 1) {
                                        window.location = window.location.href.replace('/evento/', '/listaInscricoes/')
                                    }
                                } else {
                                    window.location = window.location.href.replace('/evento/', '/inscricao/')
                                }
                            })
                    } else {
                        window.location = window.location.href.replace('/evento/', '/listaCompetidores/')
                    }
                })
            } else {
                window.location = `/verificaPerfil?ref=evento&idref=${evento.id}`
            }
        })

    }

    function handleResultados() {
        if (validaResultados > 0) {
            mensagemAlerta('Os Resultados estarão disponiveis após o termino do evento.')
        }
    }
    function handleChaves() {
        if ((!evento.chaveDisponivel || evento.chaveDisponivel === 0)) {
            mensagemAlerta('As chaves ainda não estão disponíveis.')
        }
    }
    function handleChecagem() {
        if ((!evento.aberturaChecagem)) {
            mensagemAlerta('A Checagem de atletas ainda não está disponível.')
        }
    }
    function redirecionarChecagem() {
        if (evento?.checagemAjp === 1) {
            return paginaAjp('checagem')
        } else {
            return window.location.href.replace('/evento/', '/checagem/')
        }
    }

    function inscricaoCompetidores() {
        window.location = window.location.href.replace('/evento/', '/listaCompetidores/')
    }
    function editaInscricao() {
        window.location = window.location.href.replace('/evento/' + evento.id, '/editaInscricao/' + dadosInscricao.id)
    }
    function checagem() {
        window.location = window.location.href.replace('/evento/', '/checagem/')
    }
    function paginaChave() {
        window.location = window.location.href.replace('/evento/', '/chaves/')
    }
    function paginaAjp(tipo) {
        if (evento.codEventoAjp) {
            if (tipo === 'checagem') {
                return `https://ajptour.com/pt_BR/event/${evento.codEventoAjp}/participants`;
            }
            if (tipo === 'resultado') {
                return `https://ajptour.com/pt_BR/event/${evento.codEventoAjp}/results`;
            }
            if (tipo === 'chaves') {
                return `https://ajptour.com/pt_BR/event/${evento.codEventoAjp}/schedule`;
            }
        } else {
            return `https://ajptour.com/pt_BR/federation/1/events`;
        }

    }

    function resultados() {
        window.location = window.location.href.replace('/evento/' + id, '/resultados/' + evento.id)
    }

    function mensagemComunicado(msg) {
        Swal.fire({
            title: 'Comunicado',
            html: msg,
            icon: 'info',
            customClass: {
                content: 'swal-message' // Apply custom class to the content
            }
        })
    }

    function mensagemAlerta(msg) {
        Swal.fire('Atenção', msg, 'warning')
    }

    function quebrarStringEmLinhas(str, limite) {
        const palavras = str.split(' ');
        let linhaAtual = '';
        const linhas = [];

        for (let i = 0; i < palavras.length; i++) {
            const palavra = palavras[i];

            if ((linhaAtual + palavra).length <= limite) {
                linhaAtual += (linhaAtual === '' ? '' : ' ') + palavra;
            } else {
                linhas.push(linhaAtual);
                linhaAtual = palavra;
            }
        }

        if (linhaAtual !== '') {
            linhas.push(linhaAtual);
        }

        return linhas.join('\n');
    }

    function mensagemLoading(msg) {
        Swal.fire({
            title: 'Aguarde',
            html: msg,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()
            },
        })
    }
    return (
        <div className="body-wrapper blank-loder">
            <Header />
            <div class="section_fullwidth">
                <div class="headline headline_img">
                    <div class="headline__inner">
                        <div class="headline__title">
                            <h1>{evento.nome}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section_default section-single-class-layout clearfix">

                <main class="section_default">
                    <div class="vc_row wpb_row vc_row-fluid">
                        <div class="wpb_column vc_column_container vc_col-sm-12">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="vc_empty_space" style={{ height: 28 }}><span
                                        class="vc_empty_space_inner"></span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">

                        <div class="wpb_column vc_column_container vc_col-sm-8">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_text_column wpb_content_element ">
                                        <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                        </div>
                                        <div class="row package-style-1 pnchr-package clearfix "
                                            id="package-5ff0a68edba98">
                                            <div class="vc_col-lg-5 js-hover-veil">
                                                <img style={{ height: 400, width: 300, marginTop: 10 }} src={evento.avatar} alt="" title="image-here-1" />
                                            </div>
                                            <div class="vc_col-lg-7 package-style-1__descr  package-style-1_with-img">
                                                <h3 class="package-style-1__title"> <i class="fa  fa-flag" aria-hidden="true"
                                                    style={{ color: "#eead2d" }}></i> {evento.nome}</h3>
                                                {evento.inicioEvento === evento.fimEvento ?
                                                    <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                        style={{ color: "#eead2d" }}></i> Dia {moment(evento.inicioEvento).format("DD/MM/YYYY")}</div>
                                                    :
                                                    <div class="package-style-1__text"><i class="fa  fa-calendar" aria-hidden="true"
                                                        style={{ color: "#eead2d" }}></i> De {moment(evento.inicioEvento).format("DD/MM/YYYY")} à {moment(evento.fimEvento).format("DD/MM/YYYY")}</div>
                                                }
                                                <div class="package-style-1__text"><i class="fa  fa-map-marker" aria-hidden="true"
                                                    style={{ color: "#eead2d" }}></i> {evento.endereco ? evento.endereco.cidade : null} / {evento.endereco ? evento.endereco.uf : null}</div>
                                                {!loading ?
                                                    <div class="row" style={{ width: "100%" }}>
                                                        {/* caso evento.statusInscricao == 0 ou undefined o controle deverá ser feito por data */}
                                                        {/* caso statusInscricao == 2 a inscrição será encerrada independente da data  */}
                                                        {/* caso statusInscricao == 1 (nesse caso cai no else ":") a inscrição será permitida independente da data */}
                                                        {/* caso o competidor ja tenha criado uma inscrição, poderá ver sua inscrição mesmo após o encerramento das inscrições */}
                                                        {/*Validação anterior:  (validaInicioInscricao < 0 && validaFimInscricao < 0 && !dadosInscricao.id) */}


                                                        {(((validaInicioInscricao > 0 || validaFimInscricao < 0) & (!evento.statusInscricao || evento.statusInscricao === 0) && Object.keys(dadosInscricao).length === 0) || (evento.statusInscricao === 2 && Object.keys(dadosInscricao).length === 0)) ?
                                                            <>
                                                                {!user ?
                                                                    <div onClick={() => inscricao()} style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-12" >
                                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" ><i class="fa  fa-user" aria-hidden="true" style={{ color: "#fff" }}></i> Inscrição</a>
                                                                    </div>
                                                                    :
                                                                    <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-12" >
                                                                        <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12" style={{ backgroundColor: "#A4A4A4" }}><i class="fa  fa-user" aria-hidden="true" style={{ color: "#fff" }}></i> Inscrição Encerrada</a>
                                                                    </div>
                                                                }
                                                            </>
                                                            :
                                                            <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-12" onClick={() => inscricao()}>
                                                                <a href="javascript:void(0)" class="btn_defoult btn_fill vc_col-lg-12"><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "#fff" }}></i> Inscrição</a>
                                                            </div>
                                                        }
                                                        <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-12" onClick={() => handleResultados()}>
                                                            {(validaResultados > 0) ?
                                                                <div class="btn_defoult btn_fill vc_col-lg-12" style={{ background: '#868e96', color: "#000", cursor: 'pointer' }}><i class="fa  fa-cubes" aria-hidden="true" style={{ color: "#000" }}></i> Resultados</div>

                                                                :


                                                                <a href={paginaAjp('resultado')} target='_blank' class="btn_defoult btn_fill vc_col-lg-12" style={{ background: '#868e96', color: "#000" }}><i class="fa  fa-cubes" aria-hidden="true" style={{ color: "#000" }}></i> Resultados</a>
                                                            }

                                                        </div>


                                                        <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-6" onClick={() => handleChaves()}>
                                                            {(!evento.chaveDisponivel || evento.chaveDisponivel === 0) ?
                                                                <div class="btn_defoult btn_fill vc_col-lg-12" style={{ background: '#868e96', color: "#000", cursor: 'pointer' }}><i class="fa  fa-list-ol" aria-hidden="true" style={{ color: "#000", background: '#868e96' }}></i> Chaves</div>
                                                                :

                                                                <a href={paginaAjp('chaves')} target='_blank' class="btn_defoult btn_fill vc_col-lg-12" style={{ background: '#868e96', color: "#000" }}><i class="fa  fa-list-ol" aria-hidden="true" style={{ color: "#000", background: '#868e96' }}></i> Chaves</a>
                                                            }

                                                        </div>

                                                        <div style={{ marginTop: 10 }} class="package-style-1__btn vc_col-lg-6" onClick={() => handleChecagem()}>
                                                            {!evento.aberturaChecagem ?
                                                                <div class="btn_defoult btn_fill vc_col-lg-12" style={{ background: '#868e96', color: "#000", cursor: 'pointer' }}><i class="fa  fa-check" aria-hidden="true" style={{ color: "#000", background: '#868e96' }}></i> Checagem</div>
                                                                :
                                                                <a href={
                                                                    redirecionarChecagem()} target='_blank' class="btn_defoult btn_fill vc_col-lg-12" style={{ background: '#868e96', color: "#000" }}><i class="fa  fa-check" aria-hidden="true" style={{ color: "#000" }}></i> Checagem</a>
                                                            }

                                                        </div>

                                                    </div>
                                                    :
                                                    null
                                                }
                                                {(evento.comunicado && evento?.comunicado?.exibir === 1) && (
                                                    <div style={{ marginTop: 10, }} class="package-style-1__btn vc_col-lg-12" >
                                                        <div className="btn_defoult  btn_fill vc_col-lg-12" style={{ background: "#17a2b8", cursor: "pointer", color: "#000" }} onClick={() => mensagemComunicado(evento.comunicado.mensagem)}><i className="fa fa-commenting" aria-hidden="true" style={{ color: "#000" }}></i> Comunicado</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div class="wpb_wrapper" style={{ marginTop: 40 }}>
                                            <h3>Datas e prazos</h3>
                                        </div>
                                        <div class="package-style-1 pnchr-package clearfix row">
                                            <div class="package-style-1__descr  package-style-1_with-img vc_col-lg-4">
                                                <h3 class="package-style-1__title">Data do Evento</h3>
                                                <div class="package-style-1__text">{moment(evento.inicioEvento).format("DD/MM/YYYY")}</div>
                                            </div>
                                            <div class="package-style-1__descr  package-style-1_with-img vc_col-lg-4">
                                                <h3 class="package-style-1__title">Inscrições</h3>
                                                <div class="package-style-1__text">De {moment(evento.inicioInscricao).format("DD/MM/YYYY")} até {moment(evento.fimInscricao).format("DD/MM/YYYY")}</div>
                                            </div>
                                            <div class="package-style-1__descr  package-style-1_with-img vc_col-lg-4">
                                                <h3 class="package-style-1__title">Checagem</h3>
                                                <div class="package-style-1__text">{moment(evento.checagem).format("DD/MM/YYYY")}</div>
                                            </div>
                                            <div class="package-style-1__descr  package-style-1_with-img vc_col-lg-4">
                                                <h3 class="package-style-1__title">Chaves</h3>
                                                <div class="package-style-1__text">{moment(evento.chaves).format("DD/MM/YYYY")}</div>
                                            </div>
                                            <div class="package-style-1__descr  package-style-1_with-img vc_col-lg-4">
                                                <h3 class="package-style-1__title">Cronograma</h3>
                                                <div class="package-style-1__text">{moment(evento.cronograma).format("DD/MM/YYYY")}</div>
                                            </div>
                                            <div class="package-style-1__descr  package-style-1_with-img vc_col-lg-4">
                                                <h3 class="package-style-1__title">Limite de Pagamento</h3>
                                                <div class="package-style-1__text">{moment(evento.fimInscricao).format("DD/MM/YYYY")}</div>
                                            </div>
                                        </div>
                                        <div class="vc_col-md-12 row" style={{ marginTop: 20 }}>
                                            <div style={{ marginTop: 10 }} class="vc_col-md-6" >
                                                {/* <a href="javascript:void(0)" class="btn_defoult btn_fill "><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "#fff" }}></i> Sobre o o Evento</a> */}
                                                <Accordion >
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "red" }}></i> Sobre o Evento</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid container spacing={6}>
                                                            <Grid item xs={12}>
                                                                {ReactHtmlParser(evento.sobre)}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{ marginTop: 10 }} class="vc_col-md-6" >
                                                {/* <a href="javascript:void(0)" class="btn_defoult btn_fill "><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "#fff" }}></i> Sobre o o Evento</a> */}
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}><i class="fa  fa-info" aria-hidden="true" style={{ color: "red" }}></i> Informações</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid container spacing={6}>
                                                            <Grid item xs={12}>
                                                                {ReactHtmlParser(evento.informacoes)}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{ marginTop: 10 }} class="vc_col-md-6" >
                                                {/* <a href="javascript:void(0)" class="btn_defoult btn_fill "><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "#fff" }}></i> Sobre o o Evento</a> */}
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "red" }}></i> Sobre Inscrições</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid container spacing={6}>
                                                            <Grid item xs={12}>
                                                                {ReactHtmlParser(evento.inscricao)}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{ marginTop: 10 }} class="vc_col-md-6" >
                                                {/* <a href="javascript:void(0)" class="btn_defoult btn_fill "><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "#fff" }}></i> Sobre o o Evento</a> */}
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}><i class="fa  fa-map-marker" aria-hidden="true" style={{ color: "red" }}></i> Sobre Localidade</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid container spacing={6}>
                                                            <Grid item xs={12}>
                                                                {ReactHtmlParser(evento.localidade)}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{ marginTop: 10 }} class="vc_col-md-6" >
                                                {/* <a href="javascript:void(0)" class="btn_defoult btn_fill "><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "#fff" }}></i> Sobre o o Evento</a> */}
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}><i class="fa  fa-list-alt" aria-hidden="true" style={{ color: "red" }}></i> Regras</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid container spacing={6}>
                                                            <Grid item xs={12}>
                                                                {ReactHtmlParser(evento.regras)}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{ marginTop: 10 }} class="vc_col-md-6" >
                                                {/* <a href="javascript:void(0)" class="btn_defoult btn_fill "><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "#fff" }}></i> Sobre o o Evento</a> */}
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}><i class="fa  fa-tags" aria-hidden="true" style={{ color: "red" }}></i> Categorias</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid container spacing={6}>
                                                            <Grid item xs={12}>
                                                                {ReactHtmlParser(evento.categorias)}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{ marginTop: 10 }} class="vc_col-md-6" >
                                                {/* <a href="javascript:void(0)" class="btn_defoult btn_fill "><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "#fff" }}></i> Sobre o o Evento</a> */}
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}><i class="fa  fa-check" aria-hidden="true" style={{ color: "red" }}></i> Checagem</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid container spacing={6}>
                                                            <Grid item xs={12}>
                                                                {ReactHtmlParser(evento.checagem)}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{ marginTop: 10 }} class="vc_col-md-6" >
                                                {/* <a href="javascript:void(0)" class="btn_defoult btn_fill "><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "#fff" }}></i> Sobre o o Evento</a> */}
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}><i class="fa  fa-sitemap" aria-hidden="true" style={{ color: "red" }}></i> Chaves</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid container spacing={6}>
                                                            <Grid item xs={12}>
                                                                {ReactHtmlParser(evento.chaves)}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{ marginTop: 10 }} class="vc_col-md-6" >
                                                {/* <a href="javascript:void(0)" class="btn_defoult btn_fill "><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "#fff" }}></i> Sobre o o Evento</a> */}
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}><i class="fa  fa-tachometer" aria-hidden="true" style={{ color: "red" }}></i> Pesagem</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid container spacing={6}>
                                                            <Grid item xs={12}>
                                                                {ReactHtmlParser(evento.pesagem)}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                            <div style={{ marginTop: 10 }} class="vc_col-md-6" >
                                                {/* <a href="javascript:void(0)" class="btn_defoult btn_fill "><i class="fa  fa-file-text" aria-hidden="true" style={{ color: "#fff" }}></i> Sobre o o Evento</a> */}
                                                <Accordion>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography className={classes.heading}><i class="fa  fa-trophy" aria-hidden="true" style={{ color: "red" }}></i> Premiação</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails >
                                                        <Grid container spacing={6}>
                                                            <Grid item xs={12}>
                                                                {ReactHtmlParser(evento.premiacao)}
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="wpb_column vc_column_container vc_col-sm-4 vc_col-lg-offset-0 vc_col-lg-4 vc_col-md-offset-6 vc_col-md-4 vc_hidden-md vc_hidden-sm vc_hidden-xs">
                            <div class="vc_column-inner ">
                                <div class="wpb_wrapper">
                                    <div class="wpb_widgetised_column wpb_content_element">
                                        <div class="wpb_wrapper">
                                            {administrador.id ?
                                                <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                    <h3 class="widget__title">Organização da Competição</h3>
                                                    <div class="ratings-widget__item widget-item">
                                                        <div class="ratings-widget__img js-hover-veil">
                                                            <img
                                                                src={administrador.avatar}
                                                                alt="" title="image here (2)" />
                                                        </div>
                                                        {administrador.id ?
                                                            <div class="ratings-widget__details ">
                                                                <h3 class="package-style-1__title">{administrador.nome}</h3>
                                                                <div class="package-style-1__text"><b>{administrador.email}</b></div>
                                                                <div class="package-style-1__text">Celular: ({administrador.ddd}) {administrador.contato ? administrador.contato : administrador.celular}</div>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix">
                                                <h3 class="widget__title">Resultados</h3>
                                                <div class="ratings-widget__details-title">
                                                    Os Resultados estarão dispoíveis após o término do evento.
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </div>
            <Footer />
        </div>
    );
}