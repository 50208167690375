import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const mediaQuery = window.matchMedia('(max-width: 450px)');

export default function CardDestaque({ children, imagem, detalhe, id, altura }) {
  return (
    <div id='card-destaque' key={id} className="vc_col-sm-3 ">
      <Link to="#" onClick={() => detalhe(id)}>

        <div className="vizit-cart-item " style={{ height: `${mediaQuery.matches ? 'unset' : altura}` }}>
          <div className="vizit-cart-item__img">
            <div className="vizit-cart-item__img-overlay"></div>
            <div className="vizit-cart-item__img-overlay-share">
              <p style={{ color: "#ffffff" }}>Clique aqui para saber mais</p>
            </div><img
              src={imagem}
              alt="" title="image-here" />
          </div>

          <div className="vizit-cart-item__descr" style={{ padding: '9px 15px' }}>
            {children}
          </div>
        </div>

      </Link>
    </div>
  )
}
