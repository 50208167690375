import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import banner from '../componentes/Imagens/banner.jpg'
import logo from '../componentes/Imagens/logo.png'

export default function Sobre() {


    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />

                <div id={true ? 'carousel' : ''}>

                    <div style={{display:'flex', flexDirection: 'row', justifyContent: 'center', alignItems:'center', position:'relative'}}>
                        <img src={banner} style={{ borderTop: "3px solid #eead2d", borderBottom: "3px solid #eead2d" }} alt="Imagem" />
                        <div class="headline__title" style={{position:'absolute', textAlign:'center', top:'50%', left:'50%', alignSelf:'center', justifySelf: 'center', transform:'translate(-50%, -50%)'}} >
                        <img src={logo} alt="Imagem" />
                        </div>
                    </div>

                </div>
                {/* <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Sobre</h1>
                            </div>
                            <div class="headline__description">
                                <span>Saiba tudo sobre o Procompetidor </span>
                            </div>
                        </div>
                    </div>
                </div>
                 */}
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">

                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div style={{ margin: '0 auto' }}>
                                                <div class="wpb_single_image wpb_content_element vc_align_left">

                                                    <figure class="wpb_wrapper vc_figure" style={{ textAlign: 'center' }}>
                                                        <div class="vc_single_image-wrapper   vc_box_border_grey">
                                                            {/* <img style={{ width: '400px' }}
                                                                src={logo}
                                                                class="vc_single_image-img attachment-full" alt=""
                                                                srcset={logo}
                                                            /> */}
                                                            <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Sobre</h1>
                            </div>
                            {/* <div class="headline__description">
                                <span>Saiba tudo sobre o Procompetidor </span>
                            </div> */}
                        </div>
                                                            </div>
                                                        <div id="ratings-widget-25" class="widget widget_ratings-widget clearfix" style={{ marginTop: 20 }}>

                                                            <p style={{ textAlign: 'justify' }}>
                                                                <p>
                                                                    Bem-vindo ao Abu Dhabi Jiu Jitsu Pro™ (AJP). Esta organização esportiva profissional inovadora de Jiu-Jitsu nasceu para revolucionar o mundo das competições de Jiu-Jitsu. Com mais de 310 eventos em todos os seis continentes em cada temporada, a AJP visa elevar nosso esporte a níveis de excelência e profissionalismo nunca antes alcançados.
                                                                </p>

                                                                <p>
                                                                    A AJP é a evolução natural de um movimento que começou em 2009, com o início do primeiro Campeonato Mundial Profissional de Jiu-Jitsu de Abu Dhabi. O novo evento nasceu com dois objetivos principais: levar o Jiu-Jitsu ao profissionalismo e fazer de Abu Dhabi a capital mundial do esporte. Doze anos depois, está claro que ambos os objetivos foram brilhantemente alcançados. O ADWPJJC mudou o cenário das competições de Jiu-Jitsu, fazendo com que todos os envolvidos entendam que os dias de amadorismo já se foram. Ao mesmo tempo, Abu Dhabi se tornou um farol de desenvolvimento para o esporte e um exemplo a ser seguido.
                                                                </p>

                                                                <p>
                                                                    O segundo grande passo dado foi a criação da United Arab Emirates Jiu-Jitsu Federation (UAEJJF), em 2012. Sob a orientação e com o apoio de Sua Alteza Sheikh Mohammed bin Zayed Al Nahyan Presidente dos Emirados Árabes Unidos, a UAEJJF rapidamente se tornou uma referência para o Jiu-Jitsu tanto nos Emirados Árabes Unidos quanto em todo o mundo. Desde sua criação, a UAEJJF tem apoiado o desenvolvimento de uma forte geração de atletas nos Emirados Árabes Unidos. Ela também desempenhou um papel de liderança na expansão de um circuito internacional de torneios que criam a oportunidade para o esporte florescer ao redor do planeta.
                                                                </p>

                                                                <p>
                                                                    O terceiro grande passo veio na temporada 2015/2016, com a criação do Abu Dhabi Grand Slam Jiu-Jitsu World Tour, uma série de eventos âncora em seis das cidades mais importantes do mundo. O Abu Dhabi Grand Slam Tour entrará em sua 5ª temporada em 2019/2020 como um sucesso completo.
                                                                </p>

                                                                <p>
                                                                    Ele foi abraçado pela comunidade global de Jiu-Jitsu. Cada um dos eventos se tornou o momento para o qual os atletas se preparam o ano todo. As cidades-sede Tóquio, Los Angeles, Rio de Janeiro, Abu Dhabi e Londres se tornaram centros continentais para o esporte. A sexta cidade-sede recém-adicionada, Moscou, certamente seguirá o mesmo caminho de sucesso.
                                                                </p>

                                                                <p>
                                                                    À medida que a expansão continua a ocorrer, houve a necessidade de dar um passo adiante na direção de tornar a operação de todo o sistema mais suave. O AJP vem para suprir essa necessidade, pois dá a uma equipe altamente qualificada com doze anos de experiência na execução do movimento profissional de Jiu-Jitsu o foco e o tempo para se dedicar totalmente à tarefa de lançar o melhor produto possível para a satisfação completa de todos os envolvidos, de atletas a fãs, mas também organizadores locais, mídia e parceiros comerciais.
                                                                </p>

                                                                <p>
                                                                    A AJP está sob a direção da International Vision Sports Management (IVSM), que reúne os talentos de um grupo selecionado de profissionais de ponta nas diversas áreas do conhecimento, incluindo gestão esportiva, produção de eventos, produção de televisão, orquestração de eventos ao vivo, desenvolvimento/relacionamentos com lutadores, aquisição de locais, criação/desenvolvimento de patrocínios, licenciamento internacional, marketing, patrocínio, publicidade, publicidade e relações com comissões.
                                                                </p>

                                                                <p>
                                                                    Em sua primeira temporada, de maio de 2019 a abril de 2020, a AJP organizou 73 eventos em todo o mundo, de eventos regionais a eventos continentais e globais que apresentaram os atletas de elite do Jiu-Jitsu hoje. Os eventos da AJP são transmitidos e transmitidos ao vivo para mais de 10 milhões de pessoas em todo o mundo em mais de 60 países. Nossos parceiros de mídia incluem emissoras esportivas de alto nível como Abu Dhabi Sports nos Emirados Árabes Unidos, Canal Combate no Brasil, Fox Sports na Europa, Match TV na Rússia e em todo o mundo em nosso canal online AJP.tv. Além disso, a AJP também tem uma forte presença em todos os principais canais de mídia social da atualidade, oferecendo conteúdo de alto nível com elevado potencial para se tornar viral.
                                                                </p>

                                                                <p>
                                                                    Com base em mais de uma década de experiência na organização de torneios de Jiu-Jitsu em todo o mundo, a equipe da AJP abraça a missão de ter o Jiu-Jitsu reconhecido como um esporte legítimo e Abu Dhabi, lar dos principais eventos de entretenimento do mundo que se conectam com o público por meio de conteúdo atraente na televisão, cinema, plataformas online e móveis.
                                                                </p>

                                                                <p>
                                                                    Para cumprir essa missão, a AJP está trabalhando com órgãos governamentais, entidades privadas, organizações esportivas internacionais, academias e jogadores para atingir o objetivo maior de unir todo o mundo do Jiu-Jitsu Profissional sob uma jurisdição.
                                                                </p>

                                                                <p>
                                                                    A temporada da AJP consistirá em uma variedade de eventos em todos os seis continentes em diferentes níveis de competição. Eles são:
                                                                </p>

                                                                <p>
                                                                    <li>
                                                                        210 Campeonatos Internacionais
                                                                    </li>
                                                                    <li>
                                                                        75 Campeonatos Nacionais
                                                                    </li>
                                                                    <li>
                                                                        6 Campeonatos Continentais
                                                                    </li>
                                                                    <li>
                                                                        1 Campeonato Mundial Profissional de Jiu-Jitsu de Abu Dhabi
                                                                    </li>
                                                                </p>


                                                                <p>
                                                                    Os principais objetivos da AJP são fazer do Jiu-Jitsu um empreendimento lucrativo para todos os envolvidos e também torná-lo o mais divertido possível para aqueles que assistem e competem. O objetivo é criar um sistema forte e estável que seja lucrativo para jogadores profissionais e forneça entretenimento para os fãs a longo prazo. A AJP está trabalhando diariamente para criar esse ambiente e gostaria de convidar todos aqueles que amam o Jiu-Jitsu a fazer parte dessa jornada incrível que começa agora!
                                                                </p>


                                                            </p>

                                                        </div>
                                                    </figure>
                                                </div>
                                            </div>
                                            {/* <div class="wpb_column vc_column_container  vc_col-sm-6">
                                                <div class="vc_column-inner ">
                                                    <div class="wpb_wrapper">
                                                        <div class="wpb_widgetised_column wpb_content_element">
                                                            <div class="wpb_wrapper">
                                                                <h3>SOBRE NÓS</h3>
                                                                <p>
                                                                    O Site <b>PROCompetidor</b> tem o objetivo de dar aos organizadores de eventos esportivos todo o
                                                                    suporte necessário para um pré evento de qualidade. Disponibilizando um atendimento eficiente,
                                                                    transparente e organizado. Ficamos responsáveis pela administração de inscrições,
                                                                    atendimento para atletas, checagens e chaves, facilitando ao organizador e tornando necessário
                                                                    apenas o acompanhamento destas etapas, fazendo assim uma otimização do tempo e trabalho
                                                                    no preparo do seu evento.

                                                                </p>
                                                                <h3>MISSÃO</h3>
                                                                <p>
                                                                    Nossa <b>MISSÃO</b> consiste em priorizar a excelência na prestação de serviço. O <b>PROCompetidor </b>
                                                                    busca sempre inovar com o uso de tecnologias e estratégias que estão em constante evolução,
                                                                    fornecendo assim ao organizador o melhor controle, organização e estratégias de prospecção
                                                                    possíveis para o seu evento.

                                                                </p>
                                                                <h3>VISÃO</h3>
                                                                <p>
                                                                    Nossa <b>VISÃO</b> é conquistar o mercado a níveis cada vez mais altos, fornecendo um serviço cada
                                                                    dia mais completo, e agregando reconhecimento nacional ao <b>PROCompetidor</b> como referência
                                                                    de excelência na prestação de serviço de gerenciamento online de eventos esportivos.

                                                                </p>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </main>
                </div>
                <Footer />
            </div>
        </div>
    );
}
