import {
    useState, useEffect
} from 'react'
import Header from '../componentes/Header'
import Footer from '../componentes/Footer'
import firebase from 'firebase'
import moment from 'moment'
import Swal from 'sweetalert2'
import { buscaEndereco } from '../service/WsConect'
import 'suneditor/dist/css/suneditor.min.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import base64 from 'react-native-base64'
import ModalEquipes from '../componentes/ModalEquipes'

export default function Evento() {
    const [servicos] = useState({
        auth: firebase.auth(),
        usuarios: firebase.firestore().collection("usuarios"),
        equipes: firebase.firestore().collection("equipes"),
        inscricoes: firebase.firestore().collection("inscricoes"),
        eventos: firebase.firestore().collection("eventos")
    })
    const [listaDia, setListaDia] = useState([
        { dia: '01' },
        { dia: '02' },
        { dia: '03' },
        { dia: '04' },
        { dia: '05' },
        { dia: '06' },
        { dia: '07' },
        { dia: '08' },
        { dia: '09' },
        { dia: '10' },
        { dia: '11' },
        { dia: '12' },
        { dia: '13' },
        { dia: '14' },
        { dia: '15' },
        { dia: '16' },
        { dia: '17' },
        { dia: '18' },
        { dia: '19' },
        { dia: '20' },
        { dia: '21' },
        { dia: '22' },
        { dia: '23' },
        { dia: '24' },
        { dia: '25' },
        { dia: '26' },
        { dia: '27' },
        { dia: '28' },
        { dia: '29' },
        { dia: '30' },
        { dia: '31' },
        { dia: '32' },
    ])
    const [listaMes] = useState([
        { mes: 'Janeiro', number: '01' },
        { mes: 'Fevereiro', number: '02' },
        { mes: 'Março', number: '03' },
        { mes: 'Abril', number: '04' },
        { mes: 'Maio', number: '05' },
        { mes: 'Junho', number: '06' },
        { mes: 'Julho', number: '07' },
        { mes: 'Agosto', number: '08' },
        { mes: 'Setembro', number: '09' },
        { mes: 'Outubro', number: '10' },
        { mes: 'Novembro', number: '11' },
        { mes: 'Dezembro', number: '12' },
    ])
    const [listaAno, setListaAno] = useState([])

    const [modalShow, setModalShow] = useState(false);
    const [equipes, setEquipes] = useState([])
    const [professores, setProfessores] = useState([])
    const [inscricoes, setInscricoes] = useState([])
    const [competidores, setCompetidores] = useState([])

    const [id, setId] = useState('')
    const [nome, setNome] = useState('')
    const [sobrenome, setSobrenome] = useState('')
    const [cpf, setCpf] = useState('')
    const [cpfOld, setCpfOld] = useState('')
    const [dia, setDia] = useState('')
    const [mes, setMes] = useState('')
    const [ano, setAno] = useState('')
    const [ddd, setDDD] = useState('')
    const [celular, setCelular] = useState('')
    const [sexo, setSexo] = useState('')
    const [dataNascimento, setDataNascimento] = useState('')
    const [email, setEmail] = useState('')
    const [emailOld, setEmailOld] = useState('')
    const [cep, setCep] = useState('')
    const [endereco, setEndereco] = useState('')
    const [bairro, setBairro] = useState('')
    const [numero, setNumero] = useState('')
    const [complemento, setComplemento] = useState('')
    const [uf, setUf] = useState('')
    const [cidade, setCidade] = useState('')
    const [equipe, setEquipe] = useState('')
    const [professor, setProfessor] = useState('')
    const [inputEquipe, setInputEquipe] = useState('')
    const [inputProfessor, setInputProfessor] = useState('')
    const [dadosEquipe, setDadosEquipe] = useState({})
    const [dadosProfessor, setDadosProfessor] = useState({})

    const [dica, setDica] = useState('')

    var metadata = { contentType: 'image/jpeg' };
    const [avatar, setAvatar] = useState('')
    const [upload, setUpload] = useState('');
    const [progresso, setProgresso] = useState('')

    const [loading, setLoading] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [loadingCep, setLoadingCep] = useState(false)

    useEffect(async () => {
        const list = []
        let d = 1940
        for (let index = 0; index < d; index++) {
            const element = parseInt(d) + parseInt(list.length)
            if (element === 2030) {
                break;
            } else {
                list.push(element)
            }
        }

        const snapEquipes = await servicos.equipes.get()
        const data = snapEquipes.docs.map((doc) => doc.data());
        const l = []
        const lFinal = []
        data.forEach((d) => {
            l.push(d.nome)
        })
        const array = [...new Set(l)]
        array.forEach((d) => {
            lFinal.push(data.filter((a) => a.nome === d)[0])
        })

        setListaAno(list)
        async function carregaDados() {
            servicos.auth.onAuthStateChanged((user) => {
                let id = JSON.parse(localStorage.getItem('usuarioId'))
                if (user) {
                    servicos.inscricoes.where('usuario.id', '==', user.uid).get().then((snap) => {
                        const data = snap.docs.map((doc) => doc.data());
                        setInscricoes(data)
                    })
                    servicos.usuarios.doc(user.uid).get().then((doc) => {
                        setLoading(false)
                        setId(doc.data().id)
                        setAvatar(doc.data().avatar)
                        setNome(doc.data().nome)
                        setSobrenome(doc.data().sobrenome)
                        setDataNascimento(doc.data().dataNascimento)
                        setDia(parseInt(moment(doc.data().dataNascimento).format('DD')))
                        setMes(moment(doc.data().dataNascimento).format('MM'))
                        setAno(parseInt(moment(doc.data().dataNascimento).format('YYYY')))
                        setCpf(doc.data().cpf)
                        setCpfOld(doc.data().cpf)
                        setDDD(doc.data().ddd)
                        setCelular(doc.data().celular)
                        setSexo(doc.data().sexo)
                        setEmail(doc.data().email)
                        setEmailOld(doc.data().email)
                        setCep(doc.data().endereco.cep)
                        setEndereco(doc.data().endereco.endereco)
                        setBairro(doc.data().endereco.bairro)
                        setNumero(doc.data().endereco.numero)
                        setComplemento(doc.data().endereco.complemento)
                        setUf(doc.data().endereco.uf)
                        setCidade(doc.data().endereco.cidade)
                        if(doc.data().equipe){
                            setEquipe(doc.data().equipe)

                            setDadosEquipe(lFinal.filter((d) => d.id === doc.data().equipe)[0])

                            servicos.equipes.doc(doc.data().equipe).collection('professores').onSnapshot((snap) => {
                                const data = snap.docs.map((doc) => doc.data());
                                setProfessores(data)
                            })                            
                        }
                        if(doc.data().professor){
                            setProfessor(doc.data().professor)

                            if(doc.data().equipe){
                                servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then(snap => setDadosProfessor(snap.data()))
                            }

                        }
                        setDica(doc.data().dica)

   
                        servicos.usuarios.doc(doc.data().id).collection('competidores').get().then((res) => {
                            const data = res.docs.map((d) => d.data());
                            for (let index = 0; index < data.length; index++) {
                                const element = data[index];
                                var date2 = moment(element.dataNascimento).format('YYYY')
                                var date1 = moment().format('YYYY')

                                var data1 = moment(date1, 'YYYY');
                                var data2 = moment(date2, 'YYYY');
                                console.log(data1.diff(data2, 'years'))
                                data[index].data = data1.diff(data2, 'years')
                            }
                            setCompetidores(data.sort((a,b) => {
                                if (a.nome < b.nome) {
                                    return -1;
                                } else if (a.nome > b.nome) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }))
                        })
                    })
                }else if(id){
                    servicos.inscricoes.where('usuario.id', '==', id).get().then((snap) => {
                        const data = snap.docs.map((doc) => doc.data());
                        setInscricoes(data)
                    })
                    servicos.usuarios.doc(id).get().then((doc) => {

                        try {
                            servicos.auth.signInWithEmailAndPassword(doc.data().email, base64.decode(doc.data().pass).replace(id, ''));                            
                        } catch (error) {
                            console.log(error.message);
                        }


                        setLoading(false)
                        setId(doc.data().id)
                        setAvatar(doc.data().avatar)
                        setNome(doc.data().nome)
                        setSobrenome(doc.data().sobrenome)
                        setDataNascimento(doc.data().dataNascimento)
                        setDia(parseInt(moment(doc.data().dataNascimento).format('DD')))
                        setMes(moment(doc.data().dataNascimento).format('MM'))
                        setAno(parseInt(moment(doc.data().dataNascimento).format('YYYY')))
                        setCpf(doc.data().cpf)
                        setCpfOld(doc.data().cpf)
                        setDDD(doc.data().ddd)
                        setCelular(doc.data().celular)
                        setSexo(doc.data().sexo)
                        setEmail(doc.data().email)
                        setEmailOld(doc.data().email)
                        setCep(doc.data().endereco.cep)
                        setEndereco(doc.data().endereco.endereco)
                        setBairro(doc.data().endereco.bairro)
                        setNumero(doc.data().endereco.numero)
                        setComplemento(doc.data().endereco.complemento)
                        setUf(doc.data().endereco.uf)
                        setCidade(doc.data().endereco.cidade)
                        if(doc.data().professor){
                            setProfessor(doc.data().professor)

                            if(doc.data().equipe){
                                servicos.equipes.doc(doc.data().equipe).collection('professores').doc(doc.data().professor).get().then(snap => setDadosProfessor(snap.data()))
                            }
                        }
                        setDica(doc.data().dica)

                        if (doc.data().equipe) {
                            setEquipe(doc.data().equipe)
                            setDadosEquipe(lFinal.filter((d) => d.id === doc.data().equipe)[0])
                            servicos.equipes.doc(doc.data().equipe).collection('professores').onSnapshot((snap) => {
                                const data = snap.docs.map((doc) => doc.data());
                                setProfessores(data)
                            })
                        }
                        servicos.usuarios.doc(doc.data().id).collection('competidores').get().then((res) => {
                            const data = res.docs.map((d) => d.data());
                            for (let index = 0; index < data.length; index++) {
                                const element = data[index];
                                var date2 = moment(element.dataNascimento).format('YYYY')
                                var date1 = moment().format('YYYY')

                                var data1 = moment(date1, 'YYYY');
                                var data2 = moment(date2, 'YYYY');
                                console.log(data1.diff(data2, 'years'))
                                data[index].data = data1.diff(data2, 'years')
                            }
                            setCompetidores(data.sort((a,b) => {
                                if (a.nome < b.nome) {
                                    return -1;
                                } else if (a.nome > b.nome) {
                                    return 1;
                                } else {
                                    return 0;
                                }
                            }))
                        })
                    })
                }
            })


                setEquipes(lFinal.sort((a, b) => {
                    if (a.equipe < b.equipe)
                        return -1;
                    if (a.equipe > b.equipe)
                        return 1;
                    return 0;
                }))

        }
        carregaDados();

    }, [])

    function cadastraAvatar() {
        Swal.close();
        mensagemLoading('Salvando imagem do seu avatar')
        setLoadingImage(true)
        var uploadTask = firebase.storage().ref().child('usuarios/' + id).put(avatar[0], metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setProgresso(parseInt(progress) + '%')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    setLoadingImage(false)
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                default:
                    console.log('Passou')
            }
        }, (error) => {
            switch (error.code) {
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
                default:
                    console.log('Passou')
            }
        }, () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                servicos.usuarios.doc(id).update({
                    avatar: downloadURL
                }).then(() => {
                    Swal.close();
                    mensagemSucesso('Seu perfil foi atualizado com sucesso!')
                })
            });
        });
    }

    function cadastraUsuario() {
        setLoading(true)
        if(Object.keys(dadosEquipe).length === 0){
            mensagemAlerta('Por favor, selecione sua equipe. ')
            setLoading(false)
            return;
        }
        if(Object.keys(dadosProfessor).length === 0 && inputProfessor.trim() === ''){
            mensagemAlerta('Por favor, selecione seu professor.')
            setLoading(false)
            return;
        }
        if (nome === '') {
            mensagemAlerta('Favor digitar seu primeiro nome')
            setLoading(false)
        } else if (sobrenome === '') {
            mensagemAlerta('Favor digitar seu sobrenome')
            setLoading(false)
        } else if (ddd === '' || ddd.length < 2) {
            mensagemAlerta('DDD vazio ou incompleto')
            setLoading(false)
        } else if (celular === '' || celular.length < 9) {
            mensagemAlerta('Celular vazio ou incompleto! Não esqueça de acrescentar o 9 no começo do número')
            setLoading(false)
        }
        else if (!dia) {
            mensagemAlerta('Escolha o dia de Nascimento')
            setLoading(false)
        } else if (!mes) {
            mensagemAlerta('Favor escolha o Mês de Nascimento')
            setLoading(false)
        } else if (!ano) {
            mensagemAlerta('Favor escolha o Ano de Nascimento')
            setLoading(false)
        } else if (dia + '/' + mes + '/' + ano === moment().format('DD/MM/YYYY')) {
            mensagemAlerta('Favor especifique sua data de nascimento! Importante para realizar os pagamentos nos eventos')
            setLoading(false)
        }
        // else if (moment(dataNascimento).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
        //     mensagemAlerta('Favor especifique sua data de nascimento! Importante para realizar os pagamentos nos eventos')
        //     setLoading(false)
        // }
        else if (cep === '') {
            mensagemAlerta('Favor digitar o Cep')
            setLoading(false)
        } else if (endereco === '') {
            mensagemAlerta('Favor digitar o Endereço')
            setLoading(false)
        } else if (bairro === '') {
            mensagemAlerta('Favor digite o Bairro')
            setLoading(false)
        } else {
            if (professor === '0') {
                if (inputProfessor === '') {
                    mensagemAlerta('Favor diga o nome do seu professor! Caso você seja o professor é só digitar seu nome')
                    return;
                } else {
                    mensagemLoading('Cadastrando professor...')
                    servicos.equipes.doc(equipe).collection('professores').add({
                        professor: inputProfessor
                    }).then((refProfessor) => {
                        setProfessor(refProfessor.id)
                        servicos.equipes.doc(equipe).collection('professores').doc(refProfessor.id).update({
                            id: refProfessor.id
                        }).then(() => {
                            Swal.close()
                            servicos.equipes.doc(equipe).collection('professores').doc(refProfessor.id).get().then((d) => {
                                cadastraEmail('', d.data())
                            })
                        }).catch(() => {
                            console.log('Erro ao atualizar professor')
                        })
                    }).catch(() => {
                        console.log('Erro ao cadastrar professor')
                    })
                }
            } else {
                if (equipe === '' || professor === '') {
                    mensagemAlerta('Favor selecione sua equipe e professor')
                } else {
                    cadastraEmail('', '')
                }
            }
        }

    }

    async function itemDisponivel (item, comparacao) {
        // validar se um dado item (ex: CPF ou Email) está disponvel ou já existe cadastrado. (obs: apenas na collection usuarios)
        try {
            const snap = await servicos.usuarios.where(item, '==', comparacao).get();
            if(snap.empty){
                return true
            } else if(!snap.empty){
                return false
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function  cadastraEmail(idEquipe, idProfessor) {
        mensagemLoading('Atualizando seu perfil...')

        if(cpf.trim().length === 0){
            setLoading(false);
            mensagemAlerta('Favor digite seu CPF');
            return;
        }

        if(cpf.trim().length > 0 && cpf !== cpfOld){
            const cpfDisp = await itemDisponivel('cpf', cpf);
                if(cpfDisp === false){
                    setLoading(false);
                    mensagemAlerta("Este CPF já está cadastrado no Sistema.");
                    return;
                }

            } 

// Validação do email

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(!email || !emailPattern.test(email) ){
            mensagemAlerta("Por favor, insira um email válido.");
            return;
        }

        
        if(email.trim().length > 0 && email !== emailOld){
            const emailDisp = await itemDisponivel('email', email);
                if(emailDisp === false){
                    setLoading(false);
                    mensagemAlerta("Este Email já está cadastrado no Sistema.");
                    return;
                }
            }         

        let updateUsuario = {
            nome: nome.toUpperCase(),
            sobrenome: sobrenome.toUpperCase(),
            ddd: ddd,
            celular: celular,
            // dataNascimento: dataNascimento,
            dataNascimento: ano + '-' + mes + '-' + dia,
            endereco: {
                endereco: endereco.toUpperCase(),
                bairro: bairro.toUpperCase(),
                numero: numero,
                complemento: complemento ? complemento.toUpperCase() : '',
                uf: uf,
                cidade: cidade,
                cep: cep.replace(/[^a-z0-9]/gi, '')
            },
            sexo: sexo,
            equipe: idEquipe.id ? idEquipe.id : equipe,
            professor: idProfessor.id ? idProfessor.id : professor,
            dataAtualizacao: moment().format('DD/MM/YYYY HH:mm'),
        }

        if(email && (email !== emailOld)){
        //    Se houver alteração de email, atualizar o Firebase Authentication e Firestore e relogar com o usuário com o novo email
        updateUsuario = {...updateUsuario, email: email};
            await servicos.auth.currentUser.updateEmail(email);
    
            const dadosUsuario = (await servicos.usuarios.doc(servicos.auth.currentUser.uid).get()).data();
            const pass = base64.decode(dadosUsuario.pass).replace(id, '');

            await servicos.auth.signInWithEmailAndPassword(email, pass);
        }

        if(cpf.trim().length > 0 && cpf !== cpfOld){
            updateUsuario = {...updateUsuario, cpf: cpf.trim()};
        }

        servicos.usuarios.doc(id).update(updateUsuario).then(async () => {
            Swal.close()
            if (upload) {
                cadastraAvatar()
            } else {
                if (idEquipe || idProfessor) {
                    const _equipe = (await servicos.equipes.doc(idEquipe ? idEquipe : equipe).get()).data();
                    inscricoes.forEach((d) => {
                        servicos.eventos.doc(d.evento.id).get().then((e) => {
                            if (e.data().status === 1) {
                                servicos.inscricoes.doc(d.id).update({
                                    equipe: {
                                        avatar: _equipe?.avatar ? _equipe?.avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                        equipe: _equipe ? _equipe.id : dadosEquipe.id,
                                        professor: idProfessor.id,
                                        nomeEquipe: equipe ? _equipe.equipe : dadosEquipe.equipe,
                                        nomeProfessor: idProfessor.professor
                                    }
                                })
                            }
                        })
                    })
                    mensagemSucesso('Perfil atualizado com sucesso')
                } else {
                    if (dadosEquipe.id && dadosProfessor.id) {
                        inscricoes.forEach((d) => {
                            servicos.eventos.doc(d.evento.id).get().then((e) => {
                                if (e.data().status === 1) {
                                    servicos.inscricoes.doc(d.id).update({
                                        equipe: {
                                            avatar: dadosEquipe.avatar ? dadosEquipe.avatar : 'https://i.pinimg.com/736x/f2/53/b7/f253b7780d674ab21e4c94e83198dd67.jpg',
                                            equipe: dadosEquipe.id,
                                            professor: dadosProfessor.id,
                                            nomeEquipe: dadosEquipe.equipe,
                                            nomeProfessor: dadosProfessor.professor
                                        }
                                    })
                                }
                            })
                        })
                        mensagemSucesso('Perfil atualizado com sucesso')
                    } else {
                        mensagemSucesso('Perfil atualizado com sucesso')
                    }
                }
            }
        }).catch((err) => {
            mensagemErro('Erro ao atualizar perfil ' + err.message)
            console.log('erro: ', err );
        })
    }

    function novoUsuario() {
        window.location = '/cadastraAluno/cad'
    }

    const handleNome = (event) => {
        event.persist();
        setNome(event.target.value);
    }
    const handleSobrenome = (event) => {
        event.persist();
        setSobrenome(event.target.value);
    }
    const handleDia = (event) => {
        event.persist();
        setDia(event.target.value);
    }
    const handleMes = (event) => {
        event.persist();
        setMes(event.target.value);
    }
    const handleAno = (event) => {
        event.persist();
        setAno(event.target.value);
    }
    const handleDataNascimento = (event) => {
        event.persist();
        setDataNascimento(event.target.value);
    }
    const handleEmail = (event) => {
        event.persist();
        setEmail(event.target.value);
    }
    const handleDDD = (event) => {
        event.persist();
        setDDD(event.target.value);
    }
    const handleCelular = (event) => {
        event.persist();
        setCelular(event.target.value);
    }
    const handleSexo = (event) => {
        event.persist();
        setSexo(event.target.value);
    }
    const handleCep = (event) => {
        event.persist();
        setCep(event.target.value);
        if (event.target.value.replace(/[^a-z0-9]/gi, '').length === 8) {
            buscaCep(event.target.value);
        }
    }
    const handleEndereco = (event) => {
        event.persist();
        setEndereco(event.target.value);
    }
    const handleBairro = (event) => {
        event.persist();
        setBairro(event.target.value);
    }
    const handleComplemento = (event) => {
        event.persist();
        setComplemento(event.target.value);
    }
    const handleNumero = (event) => {
        event.persist();
        setNumero(event.target.value);
    }
    const handleCidade = (event) => {
        event.persist();
        setCidade(event.target.value);
    }
    const handleUf = (event) => {
        event.persist();
        setUf(event.target.value);
    }
    const handleEquipe = (event) => {
        setProfessor('')
        setInputEquipe('')
        setInputProfessor('')
        event.persist();
        setEquipe(event.target.value);
        setDadosEquipe(equipes.filter((d) => d.id === event.target.value)[0])
        if (event.target.value !== '') {
            mensagemLoading('Carregando professores..')
            servicos.equipes.doc(event.target.value).collection('professores').onSnapshot((snap) => {
                const data = snap.docs.map((doc) => doc.data());
                setProfessores(data)
                Swal.close();
            })
        }
    }
    const handleProfessor = (event) => {
        event.persist();
        setProfessor(event.target.value);
        if(event.target.value.trim().length > 0 && event.target.value !== '0'){
            setDadosProfessor(professores.filter((d) => d.id === event.target.value)[0])
        } else{
            setDadosProfessor({});
        }
    }
    const handleInputEquipe = (event) => {
        event.persist();
        setInputEquipe(event.target.value);
    }
    const handleInputProfessor = (event) => {
        event.persist();
        setInputProfessor(event.target.value);
    }
    const handleAvatar = (event) => {
        event.persist();
        setAvatar(event.target.files)
        setUpload(URL.createObjectURL(event.target.files[0]))
    }

    function buscaCep(c) {
        mensagemLoading('Aguarde procurando CEP...')
        buscaEndereco(c).then((doc) => {
            if (doc.erro === true) {
                setLoadingCep(false)
                mensagemErro('Não achamos seu CEP! Verifique novamente ou digite manualmente')
            } else {
                Swal.close();
                setEndereco(doc.logradouro)
                setBairro(doc.bairro)
                setCidade(doc.localidade)
                setUf(doc.uf)
                setLoadingCep(false)
            }
        })
    }

    function mensagemAlerta(msg) {
        Swal.fire('Alerta', msg, 'warning')
    }
    function mensagemSucesso(msg) {
        // Swal.fire('Sucesso', msg, 'success')
        Swal.fire({
            title: 'Successo',
            text: msg,
            icon: 'success',
            allowOutsideClick: false,
            didClose: () => {
              // Mover a pagina mais pra cima para o usuário visualizar as alterações.
              window.scrollTo({
                top: 500,
                left: 0,
                behavior: 'smooth'
              });
            }
          });
    }
    function mensagemLoading(msg) {
        Swal.fire('Aguarde', msg, 'info')
    }
    function mensagemErro(msg) {
        Swal.fire('Erro', msg, 'error')
    }

    function detalheInscricao(dados) {
        localStorage.setItem('competidor', JSON.stringify(dados))
        window.location = '/cadastraAluno/' + dados.id
    }

    function compartilhaPerfil() {
        window.location.href = 'competidor/' + id
    }

    return (
        <div>
            <div className="body-wrapper blank-loder">
                <Header />
                <div class="section_fullwidth">
                    <div class="headline headline_img">
                        <div class="headline__inner">
                            <div class="headline__title">
                                <h1>Olá, {nome}</h1>
                            </div>
                            <div class="headline__description">
                                <span>Visualize seu perfil e edite</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="section_default section-single-class-layout clearfix">
                    <main class="section_default">
                        <div class="vc_row wpb_row vc_row-fluid">
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="vc_empty_space" style={{ height: 28 }}><span
                                            class="vc_empty_space_inner"></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="vc_row wpb_row vc_row-fluid vc_custom_1492882415082 vc_column-gap-30">
                            {/* <div style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-3">
                                <input type="submit" onClick={() => compartilhaPerfil()} value="Compartilhar meu Perfil" class="wpcf7-form-control wpcf7-pnchr_submit" />
                            </div> */}
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados Pessoais</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div class="package-style-1 pnchr-package clearfix vc_col-sm-4">
                                                <div >
                                                    {upload ?
                                                        <img src={upload} alt="" title="image-here-1" />
                                                        :
                                                        <img src={avatar} alt="" title="image-here-1" />
                                                    }
                                                </div>
                                                <input onChange={handleAvatar} type="file" name="name" placeholder="Escolha a imagem " />
                                            </div>
                                            <div role="form" class="wpcf7 row " id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr" >
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Nome</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleNome}
                                                            value={nome}
                                                            placeholder="Nome *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Sobrenome</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleSobrenome}
                                                            value={sobrenome}
                                                            placeholder="Sobrenome *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>CPF</h6>
                                                        <input
                                                            type="text" name="your-name" size="40" 
                                                            onChange={(e)=>setCpf(e.target.value)}
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            value={cpf}
                                                            placeholder="CPF *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Dia</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleDia} >
                                                            <option value="">Dia do Nascimento </option>
                                                            {listaDia.map((diaRef) =>
                                                                <option value={diaRef.dia} selected={dia && parseInt(dia) === parseInt(diaRef.dia) ? "selected" : null}>{diaRef.dia}</option>
                                                            )}

                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Mês de Nascimento</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleMes} >
                                                            <option value="">Mẽs do Nascimento </option>
                                                            {listaMes.map((mesRef) =>
                                                                <option value={mesRef.number} selected={mes && parseInt(mes) === parseInt(mesRef.number) ? "selected" : null}>{mesRef.mes}</option>
                                                            )}
                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Ano de Nascimento</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleAno} >
                                                            <option value="">Ano do Nascimento </option>
                                                            {listaAno.map((anoRef) =>
                                                                <option value={anoRef} selected={ano && ano === anoRef ? "selected" : null}>{anoRef}</option>
                                                            )}

                                                        </select>
                                                    </span>
                                                </p>
                                                {/* <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Data Nascimento</h6>
                                                        <input
                                                            type="date" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            value={dataNascimento}
                                                            onChange={handleDataNascimento}
                                                            placeholder="DD/MM/YYYY *" />
                                                    </span>
                                                </p> */}
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>DDD</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleDDD}
                                                            value={ddd}
                                                            maxLength={2}
                                                            placeholder="DDD *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Celular</h6>
                                                        <input
                                                            maxLength={11}
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCelular}
                                                            value={celular}
                                                            placeholder="9 + Celular *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <h6>Sexo</h6>
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <select name="select" id="select" class="form-control" onChange={handleSexo} >
                                                            <option value="">Escolha o Sexo </option>
                                                            <option value="masculino" selected={sexo === "masculino" ? "selected" : null} >Masculino </option>
                                                            <option value="feminino" selected={sexo === "feminino" ? "selected" : null}>Feminino </option>
                                                        </select>
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Email</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleEmail}
                                                            value={email}
                                                            placeholder="Email *"
                                                            required
                                                            />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <p>Guarde bem o seu email, ele será importante para receber as notificações e alterar sua senha</p>
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column  vc_col-md-12 " >
                                <div class="vc_column-inner " >
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <div class="schedule-toggle-title">
                                                    <h3 class="title-section_with-sep" >Seus competidores</h3>
                                                </div>
                                            </div>

                                            <div class="schedule-toggle-nav clearfix">
                                                <div class="form-submit__btn-wrapp btn_defoult btn_fill ">
                                                    <input type="submit" onClick={() => novoUsuario()} value="Cadastrar Competidor" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                                </div>
                                            </div>
                                            <div class="schedule-empty-space" style={{ height: 27 }}></div>
                                            <div className="table-hover">
                                                <Table>
                                                    <Thead>
                                                        <Tr>
                                                            <Th class="schedule-table__day">Avatar</Th>
                                                            <Th class="schedule-table__day">Nome</Th>
                                                            <Th class="schedule-table__day">CPF</Th>
                                                            <Th class="schedule-table__day">Sexo</Th>
                                                            <Th class="schedule-table__day">Idade</Th>
                                                            <Th class="schedule-table__day">Detalhe</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {competidores.map((competidor) =>
                                                            <Tr>
                                                                <Td style={{ height: 50 }}>
                                                                    <div className="ratings-widget__img js-hover-veil"><img
                                                                        src={competidor.avatar}
                                                                        style={{ height: 50, width: 50 }}
                                                                        alt="" title="image here (2)" />
                                                                    </div>
                                                                </Td>
                                                                <Td><h6>{competidor.nome} {competidor.sobrenome}</h6></Td>
                                                                <Td><h6>{competidor.cpf ? competidor.cpf : "Não Possui"}</h6></Td>
                                                                <Td><h6>{competidor.sexo}</h6></Td>
                                                                <Td><h6>{competidor.data} anos</h6></Td>
                                                                <Td>
                                                                    <a href="javascript:void(0)" onClick={() => detalheInscricao(competidor)} style={{ width: "100%", }} title="Clique aqui e veja o detalhe da inscrição">
                                                                        <i className="fa  fa-search" aria-hidden="true" ></i>
                                                                    </a>
                                                                </Td>
                                                            </Tr>
                                                        )}
                                                    </Tbody>
                                                </Table>
                                            </div>

                                            {competidores.length === 0 ?
                                                <p style={{ textAlign: 'center' }} ><h6>Sem competidores</h6></p>
                                                :
                                                null
                                            }

                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de Endereço</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>CEP</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCep}
                                                            value={cep}
                                                            placeholder="CEP *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Endereço</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleEndereco}
                                                            value={endereco}
                                                            placeholder="Endereço *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Bairro</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleBairro}
                                                            value={bairro}
                                                            placeholder="Bairro *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Cidade</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleCidade}
                                                            value={cidade}
                                                            placeholder="Cidade *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Estado</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleUf}
                                                            value={uf}
                                                            placeholder="UF *" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-4">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Complemento</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleComplemento}
                                                            value={complemento}
                                                            placeholder="Complemento" />
                                                    </span>
                                                </p>
                                                <p class="contact-form-name vc_col-sm-1">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>N°</h6>
                                                        <input
                                                            type="text" name="your-name" size="40"
                                                            class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                            aria-required="true" aria-invalid="false"
                                                            onChange={handleNumero}
                                                            value={numero}
                                                            placeholder="N°" />
                                                    </span>
                                                </p>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div class="wpb_text_column wpb_content_element ">
                                            <div class="wpb_wrapper">
                                                <h3>Dados de Equipe / Responsável</h3>
                                            </div>
                                            <div class="vc_empty_space" style={{ height: 27 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                            <div role="form" class="wpcf7" id="wpcf7-f147-p24-o1" lang="en-US" dir="ltr">
                                                <div class="screen-reader-response"></div>
                                                {/* <p class="contact-form-name vc_col-sm-2">
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                        <h6>Tipo de Usuário</h6>
                                                        <select name="select" id="select" class="form-control" onChange={handleTipoUsuario} >
                                                            <option value="">Escolha aqui o tipo </option>
                                                            <option value="competidor" selected={tipoUsuario === "competidor" ? "selected" : null} >Competidor </option>
                                                            <option value="responsavel" selected={tipoUsuario === "responsavel" ? "selected" : null} >Responsável </option>
                                                            <option value="professor" selected={tipoUsuario === "professor" ? "selected" : null} >Professor </option>
                                                        </select>
                                                    </span>
                                                </p> */}
                                                <p class="contact-form-name vc_col-sm-3">
                                                    <h6>Sua equipe</h6>
                                                    <span class="wpcf7-form-control-wrap your-name">
                                                    <input type='text' name="select" 
                                                        class="form-control"
                                                        value={dadosEquipe?.equipe}
                                                        style={{cursor:"pointer"}}
                                                          onClick={() => setModalShow(true)}
                                                          placeholder='Selecione sua equipe'
                                                        />                                                        
                                                        {/* <select name="select" id="select" class="form-control" onChange={handleEquipe} >
                                                            <option value="">Escolha a Equipe </option>
                                                            <option value="0" >Não está na lista </option>
                                                            {equipes.sort((a, b) => {
                                                                if (a.equipe.toUpperCase() < b.equipe.toUpperCase())
                                                                    return -1;
                                                                if (a.equipe.toUpperCase() > b.equipe.toUpperCase())
                                                                    return 1;
                                                                return 0;
                                                            }).map((lEquipe) =>
                                                                <option value={lEquipe.id} selected={equipe ? equipe === lEquipe.id ? "selected" : null : null} >{lEquipe.equipe.toUpperCase()}</option>
                                                            )}
                                                        </select> */}
                                                    </span>
                                                </p>
                                                {equipe !== "0" ?
                                                    <p class="contact-form-name vc_col-sm-3">
                                                        <h6>Seu Professor</h6>
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <select name="select" id="select" class="form-control" onChange={handleProfessor}>
                                                                <option value="">Escolha o Professor </option>
                                                                <option value="0" >Não está na lista </option>
                                                                {professores.sort((a, b) => {
                                                                    if (a.professor.toUpperCase() < b.professor.toUpperCase())
                                                                        return -1;
                                                                    if (a.professor.toUpperCase() > b.professor.toUpperCase())
                                                                        return 1;
                                                                    return 0;
                                                                }).map((lProfessor) =>
                                                                    <option value={lProfessor.id} selected={professor ? professor === lProfessor.id ? "selected" : null : null} >{lProfessor.professor.toUpperCase()}</option>
                                                                )}
                                                            </select>
                                                        </span>
                                                    </p>
                                                    :
                                                    null
                                                }
                                                {/* {equipe === "0" ?
                                                    <p class="contact-form-name vc_col-sm-3">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h6>Nome da Equipe</h6>
                                                            <input
                                                                type="text" name="your-name" size="40"
                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false"
                                                                onChange={handleInputEquipe}
                                                                value={inputEquipe.toUpperCase()}
                                                                placeholder="Digite o nome da equipe *" />
                                                        </span>
                                                    </p>
                                                    :
                                                    null
                                                } */}
                                                {equipe === "0" || professor === "0" ?
                                                    <p class="contact-form-name vc_col-sm-3">
                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <h6>Nome do Professor</h6>
                                                            <input
                                                                type="text" name="your-name" size="40"
                                                                class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                aria-required="true" aria-invalid="false"
                                                                onChange={handleInputProfessor}
                                                                value={inputProfessor.toUpperCase()}
                                                                placeholder="Digite o nome do professor *" />
                                                        </span>
                                                    </p>
                                                    :
                                                    null
                                                }

                                            </div>
                                            <div class="vc_empty_space" style={{ height: 40 }}><span class="vc_empty_space_inner"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="wpb_column vc_column_container vc_col-sm-12">
                                <div style={{ marginTop: 20 }} class="form-submit__btn-wrapp btn_defoult btn_fill vc_col-sm-3">
                                    <input type="submit" onClick={() => cadastraUsuario()} value="Salvar Dados" class="wpcf7-form-control wpcf7-pnchr_submit" />
                                </div>
                            </div>

                        </div>

                    </main>
                </div>
                <Footer />
            </div >
            {modalShow && (
                <ModalEquipes isOpen={modalShow} setIsOpen={setModalShow} setEquipe={setEquipe} setDadosEquipe={setDadosEquipe} setProfessores={setProfessores}
                setDadosProfessor={setDadosProfessor}
                />
            )}
        </div >
    );
}
