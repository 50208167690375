import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Carousel.css'
import banner_ajp_1 from './Imagens/banner_ajp_1.png'

export default function Carousel() {

  const [banners, setBanners] = useState([]);
  const [servicos] = useState({
    configuracoes: firebase.firestore().collection("configuracoes")
  })

  useEffect(() => {

    // servicos.configuracoes.get().then((snap =>{
    //     const config = snap.docs.map(c => {return {...c.data(), id: c.id}})
    //     if(config[0].id){
    //         servicos.configuracoes.doc(config[0].id).collection('banners').get().then(snap =>{
    //           if(snap.size > 0){
    //             const data = snap.docs.map(d => d.data())
    //             setBanners(data.sort(((a,b) => a.posicao - b.posicao)));
    //           }
    //         })
    //     }
    // }))

  }, [])

  const [autoplaySpeed, setAutoplaySpeed] = useState(3000); // Default autoplay speed

  const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: autoplaySpeed,
    arrows: false,

    beforeChange: (oldIndex, newIndex) => {

      let customAutoplaySpeeds = {}
      banners.forEach((banner, index) => {
        customAutoplaySpeeds = { ...customAutoplaySpeeds, [index]: banner.duracao * 1000 }
      })

      // Check if the next slide has a custom autoplay speed defined
      if (customAutoplaySpeeds[newIndex] !== undefined) {
        // Set the custom autoplay speed for the next slide
        setAutoplaySpeed(customAutoplaySpeeds[newIndex]);
      } else {
        // Set the default autoplay speed if no custom speed is defined
        setAutoplaySpeed(3000);
      }

    }
  };

  return (

    <div id={true ? 'carousel' : ''}>

      <div >
        <img src={banner_ajp_1} style={{borderTop: "3px solid #eead2d", borderBottom: "3px solid #eead2d"}} alt="Imagem" />
      </div>

    </div>

    // <div id={banners.length > 0 ? 'carousel' : ''}>

    //   {banners.length === 1 && (
    //     banners[0].midia === 'imagem' ?
    //       banners[0].link ?
    //         (
    //           <div>
    //             <a href={banners[0].link} target='_blank'>
    //               <img src={banners[0].urlArquivoBanner} alt="Imagem" />
    //             </a>
    //           </div>
    //         ) :
    //         (
    //           <div>
    //             <img src={banners[0].urlArquivoBanner} alt="Imagem" />
    //           </div>
    //         )
    //       :
    //       banners[0].midia === 'video' && (
    //         banners[0].link ?
    //           (
    //             <div>
    //               <a href={banners[0].link} target='_blank'>
    //                 <video id='carousel-video' autoPlay loop muted>
    //                   <source src={banners[0].urlArquivoBanner} type={banners[0].contentType} />
    //                   Your browser does not support the video tag.
    //                 </video>
    //               </a>
    //             </div>
    //           ) :
    //           (
    //             <div>
    //               <video id='carousel-video' autoPlay loop muted>
    //                 <source src={banners[0].urlArquivoBanner} type={banners[0].contentType} />
    //                 Your browser does not support the video tag.
    //               </video>
    //             </div>
    //           )
    //       )
    //   )}

    // <Slider {...settings}>

    //     {banners.length > 1 && (
    //       banners.map(banner => (
    //         banner.midia === 'imagem' ?
    //           banner.link ?
    //             (
    //               <div>
    //                 <a href={banner.link} target='_blank'>
    //                   <img src={banner.urlArquivoBanner} alt="Imagem" />
    //                 </a>
    //               </div>
    //             ) :
    //             (
    //               <div>
    //                 <img src={banner.urlArquivoBanner} alt="Imagem" />
    //               </div>
    //             )
    //           :
    //           banner.midia === 'video' && (
    //             banner.link ?
    //               (
    //                 <div>
    //                   <a href={banner.link} target='_blank'>
    //                     <video id='carousel-video' autoPlay loop muted>
    //                       <source src={banner.urlArquivoBanner} type={banner.contentType} />
    //                       Your browser does not support the video tag.
    //                     </video>
    //                   </a>

    //                 </div>
    //               ) :
    //               (
    //                 <div>
    //                   <video id='carousel-video' autoPlay loop muted>
    //                     <source src={banner.urlArquivoBanner} type={banner.contentType} />
    //                     Your browser does not support the video tag.
    //                   </video>
    //                 </div>
    //               )
    //           )

    //       )

    //       )
    //     )}

    //   </Slider>
    // </div>

  );
}
